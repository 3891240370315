"use client";
import Image from "next/image";

const logos = [
  {
    name: "PyTorch",
    imageUrl: "/logo-carousel/pytorch.png",
  },
  {
    name: "TensorFlow",
    imageUrl: "/logo-carousel/tensorflow.png",
  },
  {
    name: "Unreal Engine",
    imageUrl: "/logo-carousel/unreal-engine.png",
  },
  {
    name: "Blender",
    imageUrl: "/logo-carousel/blender.png",
  },
  {
    name: "NVIDIA CUDA",
    imageUrl: "/logo-carousel/nvidia.png",
  },
  {
    name: "Parsec",
    imageUrl: "/logo-carousel/parsec.png",
  },
];

export function LogoCarousel() {
  return (
    <section className="relative z-10 py-16 px-4 md:px-6">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold text-white text-center mb-12">
          Hỗ trợ đa dạng công nghệ
        </h2>
        <div className="bg-white/[0.02] backdrop-blur-sm border border-white/10 rounded-2xl">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
            <div className="overflow-hidden relative">
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-8">
                {logos.map((logo, index) => (
                  <div
                    key={`logo-${index}`}
                    className="flex items-center justify-center group"
                  >
                    <div className="relative w-[160px] h-[50px] transition-all duration-300 group-hover:scale-105">
                      <Image
                        src={logo.imageUrl}
                        alt={logo.name}
                        fill
                        sizes="(max-width: 768px) 160px, 160px"
                        className="object-contain"
                        priority={index < 3}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
