'use client'

import { motion } from 'framer-motion'
import { Settings, Users, Shield, Cpu, Zap, ArrowRight } from 'lucide-react'

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="group relative overflow-hidden bg-gradient-to-br from-white/5 to-white/10 rounded-2xl p-8 backdrop-blur-sm border border-white/10 shadow-xl"
  >
    <div className="absolute inset-0 bg-gradient-to-br from-primary/20 to-purple-500/20 opacity-0 transition-opacity duration-300 group-hover:opacity-100" />
    <div className="relative z-10">
      <div className="mb-6 inline-block rounded-full bg-primary/20 p-3">
        {icon}
      </div>
      <h3 className="text-2xl font-semibold text-white mb-4">{title}</h3>
      <p className="text-gray-300">{description}</p>
    </div>
  </motion.div>
)

export function Features() {
  return (
    <section className="relative z-10 py-32 px-4 md:px-6 overflow-hidden">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl md:text-5xl font-bold text-white mb-6">
            Tính năng Cloud GPU
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Khám phá sức mạnh và linh hoạt của dịch vụ Cloud GPU của chúng tôi, được thiết kế để đáp ứng mọi nhu cầu của bạn.
          </p>
        </motion.div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <FeatureCard
            icon={<Zap className="h-8 w-8 text-primary" />}
            title="Hiệu suất cao"
            description="GPU mạnh mẽ cho xử lý nhanh chóng các tác vụ AI và Machine Learning."
          />
          <FeatureCard
            icon={<Settings className="h-8 w-8 text-primary" />}
            title="Tùy chỉnh linh hoạt"
            description="Chọn cấu hình phù hợp với nhu cầu của bạn, từ đơn GPU đến multi-GPU."
          />
          <FeatureCard
            icon={<Users className="h-8 w-8 text-primary" />}
            title="Hỗ trợ 24/7"
            description="Đội ngũ kỹ thuật chuyên nghiệp sẵn sàng hỗ trợ bạn mọi lúc."
          />
          <FeatureCard
            icon={<Shield className="h-8 w-8 text-primary" />}
            title="Bảo mật tối ưu"
            description="Hệ thống bảo mật tiên tiến, đảm bảo an toàn cho dữ liệu và công việc của bạn."
          />
          <FeatureCard
            icon={<Cpu className="h-8 w-8 text-primary" />}
            title="Công nghệ tiên tiến"
            description="Sử dụng các GPU mới nhất từ NVIDIA, đảm bảo hiệu suất tối đa cho mọi tác vụ."
          />
          <FeatureCard
            icon={<ArrowRight className="h-8 w-8 text-primary" />}
            title="Mở rộng dễ dàng"
            description="Dễ dàng nâng cấp hoặc hạ cấp tài nguyên theo nhu cầu thực tế của bạn."
          />
        </div>
      </div>
      <div className="absolute top-1/2 left-0 w-64 h-64 bg-primary/30 rounded-full filter blur-3xl opacity-20 -z-10" />
      <div className="absolute top-1/3 right-0 w-96 h-96 bg-purple-500/30 rounded-full filter blur-3xl opacity-20 -z-10" />
    </section>
  )
}
