"use client";

import dynamic from 'next/dynamic';
import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";

// Dynamically import TypeAnimation with preload
const TypeAnimation = dynamic(
  () => import('react-type-animation').then(mod => mod.TypeAnimation),
  { 
    ssr: false,
    loading: () => (
      <span className="bg-gradient-to-r from-primary to-purple-500 text-transparent bg-clip-text">
        AI và Machine Learning
      </span>
    )
  }
);

export function Hero() {
  return (
    <div className="flex flex-col items-center justify-center px-4 pt-24 pb-20 text-center min-h-[calc(100vh-4rem)]">
      {/* Animated badge */}
      <motion.div 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="inline-flex items-center rounded-2xl bg-gradient-to-r from-primary/10 to-purple-500/10 border border-white/10 px-4 py-2 text-sm backdrop-blur-xl mb-6"
      >
        <motion.span 
          className="mr-3 relative"
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <motion.span 
            className="absolute -top-1 -right-1 w-2 h-2 bg-green-500 rounded-full"
            animate={{ 
              scale: [1, 1.2, 1],
              opacity: [1, 0.8, 1]
            }}
            transition={{ 
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
          <svg className="w-4 h-4 text-green-500" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <motion.path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20Z"
              fill="currentColor"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            />
            <motion.circle
              cx="12"
              cy="12"
              r="4"
              fill="currentColor"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.3, delay: 0.6 }}
            />
          </svg>
        </motion.span>
        <span className="text-white font-medium">Thuê GPU theo giờ</span>
        <span className="ml-2 bg-green-500/10 rounded-lg px-2 py-0.5 text-green-500 text-xs font-medium border border-green-500/20">24/7</span>
      </motion.div>

      <div className="max-w-4xl">
        <h1 className="mb-6 text-4xl md:text-6xl font-bold flex flex-col">
          <span className="text-white mb-2">Máy chủ GPU cho</span>
          <div className="relative min-h-[80px] md:min-h-[96px]">
            <TypeAnimation
              sequence={[
                "AI và Machine Learning",
                2500,
                "Design và Render 3D",
                2500,
                "Cloud Gaming",
                2500,
              ]}
              wrapper="span"
              speed={50}
              cursor={true}
              repeat={Infinity}
              className="bg-gradient-to-r from-primary to-purple-500 text-transparent bg-clip-text type-cursor inline-block"
            />
          </div>
        </h1>

        <p className="mb-8 text-lg md:text-xl text-gray-300">
          Dịch vụ cho thuê máy chủ GPU giá rẻ, chất lượng cao.
        </p>

        <Link href="/pricing" prefetch>
          <Button
            size="lg"
            className="relative overflow-hidden bg-primary hover:bg-primary/90 text-white px-8 py-4 text-lg group"
          >
            <span className="relative z-10 flex items-center justify-center">
              Xem bảng giá
              <ArrowRight className="ml-2 h-5 w-5" />
            </span>
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600 to-primary opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </Button>
        </Link>

        <p className="mt-6 text-sm text-gray-400">
          Thanh toán theo giờ. Không cần cam kết dài hạn.
        </p>
      </div>

      {/* Optimized dashboard preview */}
      <div className="relative mt-16 w-full max-w-5xl">
        <div className="rounded-xl bg-black/50 backdrop-blur-sm border border-white/10">
          <div className="flex items-center gap-2 p-4 border-b border-white/10">
            <div className="w-3 h-3 rounded-full bg-red-500/80" />
            <div className="w-3 h-3 rounded-full bg-yellow-500/80" />
            <div className="w-3 h-3 rounded-full bg-green-500/80" />
            <span className="ml-auto text-sm text-gray-400">GPU Dashboard</span>
          </div>

          <div className="p-6">
            <Image
              src="/placeholder.svg"
              alt="Bảng điều khiển GPU"
              width={800}
              height={400}
              className="w-full h-auto rounded-lg"
              priority
              sizes="(max-width: 768px) 100vw, 800px"
              placeholder="blur"
              blurDataURL="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODAwIiBoZWlnaHQ9IjQwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSIjMTExMjI3Ii8+PC9zdmc+"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
