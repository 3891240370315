'use client'

import { motion } from 'framer-motion'
import { Button } from "@/components/ui/button"
import { CheckCircle } from 'lucide-react'

interface PricingPlan {
  name: string;
  price: string;
  features: string[];
  recommended?: boolean;
}

const plans: PricingPlan[] = [
  {
    name: "Basic GPU",
    price: "50.000",
    features: ["1x NVIDIA T4 GPU", "4 vCPU", "16GB RAM", "100GB SSD", "1TB Bandwidth"],
    recommended: false
  },
  {
    name: "Pro GPU",
    price: "100.000",
    features: ["1x NVIDIA V100 GPU", "8 vCPU", "32GB RAM", "200GB SSD", "2TB Bandwidth"],
    recommended: true
  },
  {
    name: "Enterprise GPU",
    price: "200.000",
    features: ["2x NVIDIA A100 GPU", "16 vCPU", "64GB RAM", "500GB SSD", "5TB Bandwidth"],
    recommended: false
  }
]

export function Pricing() {
  return (
    <section className="relative z-10 py-24 px-4 md:px-6 overflow-hidden">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-5xl font-bold text-white text-center mb-8">
          Bảng giá linh hoạt
        </h2>
        <p className="text-xl text-gray-300 text-center mb-16 max-w-3xl mx-auto">
          Chọn gói dịch vụ phù hợp với nhu cầu của bạn. Tất cả các gói đều bao gồm hỗ trợ kỹ thuật 24/7 và bảo mật cao cấp.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 relative">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`relative bg-white/10 backdrop-blur-xl rounded-2xl p-8 flex flex-col shadow-2xl ${
                plan.recommended ? 'border-2 border-primary' : ''
              }`}
            >
              {plan.recommended && (
                <span className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-primary text-white px-4 py-1 rounded-full text-sm font-semibold">
                  Phổ biến nhất
                </span>
              )}
              <h3 className="text-2xl font-semibold text-white mb-4">{plan.name}</h3>
              <div className="text-5xl font-bold text-primary mb-6">
                {plan.price} ₫<span className="text-sm font-normal text-gray-300">/giờ</span>
              </div>
              <ul className="mb-8 flex-grow space-y-4">
                {plan.features.map((feature, featureIndex) => (
                  <li key={featureIndex} className="flex items-center text-gray-300">
                    <CheckCircle className="h-5 w-5 text-primary mr-2 flex-shrink-0" />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
              <Button 
                className={`w-full text-lg py-6 ${
                  plan.recommended 
                    ? 'bg-primary hover:bg-primary/90' 
                    : 'bg-white/20 hover:bg-white/30 text-white'
                }`}
              >
                {plan.recommended ? 'Bắt đầu dùng thử' : 'Chọn gói'}
              </Button>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  )
}
